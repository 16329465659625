/* Section Base */
.sectionBase {
  z-index: 2;
  position: relative;
}

/* Carousel Viewport */
.carouselViewport {
  margin: 0;
  overflow: hidden;
  position: relative;
  display: block;
  z-index: 2;
}

@media (min-width: 345px) {
  .carouselViewport {
    margin: 0 0;
  }
}

@media (min-width: 744px) {
  .carouselViewport {
    max-width: 565px;
    margin: 0 auto 0 auto;
    padding: 0 0;
  }
}

@media (min-width: 1024px) {
  .carouselViewport {
    max-width: 53rem;
    margin: 0 auto 0 auto;
  }
}

/* Items */
.items {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.items[data-activeslide='0'] {
  transform: translateX(0);
}

.items[data-activeslide='1'] {
  transform: translateX(-100%);
}

.items[data-activeslide='2'] {
  transform: translateX(-200%);
}

/* Keyframes */
@keyframes activatePreviousSlide {
  0% {
    transform: translateX(-2rem);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes activateNextSlide {
  0% {
    transform: translateX(2rem);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Item */
.item {
  min-width: 100%;
  opacity: 0;
}

.item.activeSlide.previous {
  animation: activatePreviousSlide 200ms linear forwards;
}

.item.activeSlide.next {
  animation: activateNextSlide 200ms linear forwards;
}

/* Circle */
@keyframes activateCircle {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

.circleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 3.75rem;
  margin: 2rem auto 0 auto;
  height: 3rem;

  @media (min-width: 1024px) {
    margin-right: 0;
  }
}

.circle {
  display: inline-block;
  background-color: rgb(var(--core-5));
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  position: relative;
  text-align: center;
}

.circle.active {
  margin-top: 0;
  border-radius: 1rem;
  background-color: rgb(var(--core-7));
  animation: activateCircle 100ms ease-in forwards;
}
